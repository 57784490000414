import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { useUpdateChat } from '@api/chats/hooks/use-update-chat'
import { useAppDispatch, useAppSelector } from '@app/flow/hooks'
import { ChevronLeftIcon } from '@components/icons'
import { useSidePage } from '@hooks/use-side-page'
import { useTabTitleWithCounter } from '@hooks/use-tab-title-with-counter'
import { About } from '@modules/about'
import { Files } from '@modules/files'
import { EditDetailsForm, EditValues } from '@modules/forms/edit-details'
import { channelNameValidationSchema } from '@modules/forms/edit-details/data'
import { Members } from '@modules/members'
import { NativeChatsAboutTab } from '@modules/native-chats/about'
import { SidepageHeader } from '@modules/side-page/header'
import { getFeatureFlag } from '@utils/flags-manager'
import { isNativeChatsApp } from '@utils/is-native-chats-app'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { formatConversationName } from 'src/entities/conversations/lib/format-conversation-name'
import { Conversation } from 'src/entities/conversations/model/conversation'
import { selectConversationById } from 'src/entities/conversations/model/entity-adapter'
import { updateConversation } from 'src/entities/conversations/model/slice'
import { Container, Tab, Tabs, TabsVariant } from 'ui'

export enum EditType {
  channelName = 'channelName',
  channelDescription = 'channelDescription',
}

export const Details = () => {
  const { sidePageChatId } = useSidePage()
  const conversationFromSlice = useAppSelector(({ conversations }) =>
    selectConversationById(conversations, sidePageChatId || '')
  )
  const { data: conversationDetails } = useGetChatDetails(sidePageChatId || undefined)
  const conversation = conversationFromSlice ?? conversationDetails
  const [editType, setEditType] = useState<EditType | null>(null)
  const { meData } = useGetMe()
  const dispatch = useAppDispatch()
  const { mutate: updateChannelInfo } = useUpdateChat({
    onErrorCallback: () => toast.error(`${t('toastMessages.changesNotSaved')}`),
    onSuccessCallback: (data) => {
      dispatch(updateConversation({ id: data.id, updatedData: { name: data.name } }))
      setEditType(null)
    },
  })
  const { t } = useTranslation('details')
  const { t: tChat } = useTranslation('chat')

  const activeChatUsers = useMemo(
    () => conversation?.chatUsers?.filter(({ leftChat }) => !leftChat),
    [conversation]
  )

  const membersTabTitle = useTabTitleWithCounter(activeChatUsers ?? [], t('members'))

  const sidepageTitle = useMemo(() => {
    if (!conversation || !meData) return ''
    return formatConversationName(conversation, meData.userId, tChat)
  }, [conversation, tChat])

  const channelDetailsTitle = useMemo(() => {
    switch (editType) {
      case EditType.channelName:
        return t('renameChannel')
      case EditType.channelDescription:
        return t('editDescription')
      default:
        return sidepageTitle
    }
  }, [sidepageTitle, editType])

  if (!conversation) {
    return null
  }
  // TODO: Handle edit channel description, BE is not supporting update of description yet
  const onEditSubmit = (values: EditValues) => {
    if (!conversation || !editType) return

    if (editType === EditType.channelName) {
      updateChannelInfo({ chatId: conversation.id, requestBody: { name: values.channelName } })
    }
  }

  const commonEditProps = {
    onCancel: () => setEditType(null),
    onSubmit: onEditSubmit,
  }

  const editNameProps = {
    ...commonEditProps,
    fieldKey: EditType.channelName,
    label: t('channelName'),
    placeholder: t('enterName'),
    initialValue: conversation.name,
    validationSchema: channelNameValidationSchema,
  }

  const editDescriptionProps = {
    ...commonEditProps,
    fieldKey: EditType.channelDescription,
    maxCharacters: 100,
    label: t('description'),
    placeholder: t('enterDescription'),
    initialValue: conversation.description,
    disabled: false, // Temporary disable, BE is not supporting update of description yet
  }

  const tabs = [
    <Tab title={t('about')} key={t('about')}>
      <About setEditType={setEditType} conversation={conversation as Conversation} />
    </Tab>,
    <Tab title={membersTabTitle} key={t('membersTabTitle')}>
      <Members />
    </Tab>,
  ]

  const nativeChatsTabs = [
    <Tab title={t('about')} key={t('about')}>
      <NativeChatsAboutTab setEditType={setEditType} />
    </Tab>,
    <Tab title={membersTabTitle} key={t('membersTabTitle')}>
      <Members />
    </Tab>,
  ]

  if (getFeatureFlag('showOnProd')) {
    tabs.push(
      <Tab title={t('files')} key={t('files')}>
        <Files />
      </Tab>
    )
  }

  return (
    <>
      {channelDetailsTitle && (
        <SidepageHeader
          title={channelDetailsTitle}
          preFix={
            editType && (
              <button onClick={() => setEditType(null)} aria-label={t('goBack')}>
                <ChevronLeftIcon />
              </button>
            )
          }
          pb="1.2rem"
        />
      )}
      <Container height="calc(100% - 5rem)" flex="1 1 100%">
        {editType ? (
          <EditDetailsForm
            {...(editType === EditType.channelName ? editNameProps : editDescriptionProps)}
          />
        ) : (
          <Tabs variant={TabsVariant.UNDERLINE} listMarginBottom="1.6rem">
            {isNativeChatsApp() ? nativeChatsTabs : tabs}
          </Tabs>
        )}
      </Container>
    </>
  )
}
