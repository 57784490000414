import { useAppDispatch, useAppSelector } from '@app/flow/hooks'
import { useConversationId } from '@hooks/use-conversation-id'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { selectConversationById } from '../model/slice'
import { syncMessages as syncAction } from '../model/slice'
import { selectMessages } from '../model/slice'
import { syncMessages } from './sync-messages'

const MESSAGES_QUERY_KEY = 'Messages'

export const useSyncAndGetMessages = (chatId: string) => {
  const dispatch = useAppDispatch()

  const conversationId = useConversationId()

  const conversationMessaging = useAppSelector(({ conversation }) =>
    selectConversationById(conversation, conversationId || chatId)
  )

  const messages = useMemo(() => {
    if (conversationMessaging) {
      return selectMessages(conversationMessaging.messages)
    }
    return []
  }, [conversationMessaging])

  const lastMessage = messages[messages.length - 1] || {
    attachments: [],
    chatId: '',
    clientMessageId: '',
    createdAt: '',
    createdBy: '',
    editedAt: null,
    isDeleted: false,
    messageId: '',
    originalMessage: '',
    reactions: [],
    sourceLanguageCode: 'en',
    translations: [],
    updatedAt: '',
  }

  const query = useQuery(
    [MESSAGES_QUERY_KEY],
    () => {
      return syncMessages(
        20,
        lastMessage.createdAt.toString(),
        conversationId || chatId,
        lastMessage.messageId
      )
    },
    {
      onSuccess: (data) => {
        dispatch(syncAction({ data, chatId: conversationId || chatId }))
      },
    }
  )

  return {
    messages,
    ...query,
  }
}
