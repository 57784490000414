import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { useToggleMute } from '@api/account/hooks/use-toggle-mute'
import { useCreateChat } from '@api/chats/hooks/use-create-chat'
import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { MailIcon, PhoneIcon } from '@components/icons'
import { List } from '@components/list'
import { useSidePage } from '@hooks/use-side-page'
import { DeleteChatModal } from '@modules/modals/delete-chat'
import { ButtonsContainer } from '@modules/native-chats/about/styled'
import { routes } from '@routes/flow/routes'
import { getFeatureFlag } from '@utils/flags-manager'
import { getFullName } from '@utils/helpers'
import { format } from 'date-fns'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import {
  Avatar,
  Button,
  LogoutIcon,
  SendIcon,
  Text,
  TimeIcon,
  ToggleButton,
  TranslationIcon,
  useModal,
  UserIcon,
} from 'ui'
import { UserDetailProps } from '.'

export const UserActive: React.FC<UserDetailProps> = ({ user }) => {
  const {
    userId,
    firstName,
    lastName,
    phoneNumber,
    email,
    jobTitle,
    avatar,
    color,
    timeZone,
    isDeleted,
    languages,
  } = user
  const [isMuted] = useToggleMute()
  const { t } = useTranslation('user-detail')
  const navigate = useNavigate()
  const { usersAvatarUrl, enabled } = useGetUsersAvatar({ userId, avatarId: avatar?.id })
  const userLanguages = languages?.map(({ translatedName }) => translatedName).join(', ')
  const { openModal } = useModal()
  const { sidePageChatId } = useSidePage()
  const { chatId } = useParams()
  const { data: chatDetails } = useGetChatDetails(chatId)
  const isOneToOneChat = chatDetails && chatDetails.chatUsers.length <= 2

  const usersLocalTime = () => {
    if (!timeZone || !timeZone.id) return getFormatedTime(new Date())
    const date = new Date()
    const formattedDate = date.toLocaleString('en-US', { timeZone: timeZone.id })
    return getFormatedTime(new Date(formattedDate))
  }

  const { createChat, data, isCreateChatSuccess } = useCreateChat()

  const handleSendClick = useCallback(() => {
    createChat({ userIds: [userId] })
  }, [data, isCreateChatSuccess])

  useEffect(() => {
    if (isCreateChatSuccess) {
      navigate(`${routes.chats}/${data?.id}`)
    }
  }, [data, isCreateChatSuccess])

  const getFormatedTime = (time: Date) => {
    return `${format(time, 'p')} local time`
  }

  const fullName = getFullName(firstName, lastName)

  const info = [
    {
      icon: <UserIcon aria-label={t('positionHiddenLabel')} />,
      text: jobTitle,
    },
    {
      icon: <TranslationIcon aria-label={t('languagesHiddenLabel')} />,
      text: userLanguages,
    },
    {
      icon: <TimeIcon aria-label={t('localTimeHiddenLabel')} />,
      text: timeZone ? usersLocalTime() : undefined,
    },
  ]

  const contacts = [
    {
      icon: <MailIcon aria-label={t('emailHiddenLabel')} />,
      text: email ? <a href={`mailto:${email}`}>{email}</a> : undefined,
    },
    {
      icon: <PhoneIcon aria-label={t('phoneHiddenLabel')} />,
      text: phoneNumber,
    },
  ]

  return (
    <>
      <Avatar
        name={fullName}
        size="28.8rem"
        imgUrl={usersAvatarUrl ?? avatar?.mediaUri}
        label={firstName}
        isDeleted={isDeleted}
        fontSize="8rem"
        bgColor={`#${color}`}
        iconSize={192}
        borderRadius="1.6rem"
        isAvatarExists={enabled}
      />
      <List items={info} my="1.6rem" />
      <Button
        width="100%"
        variant="secondary"
        size="sm"
        preFix={isDeleted ? null : <SendIcon />}
        onClick={handleSendClick}
      >
        {isDeleted ? t('viewMsgHistoryButton') : t('msgButton')}
      </Button>
      {!isDeleted && <List title={t('subTitleContact')} items={contacts} mt="2.8rem" />}
      {getFeatureFlag('showOnProd') && (
        <>
          <Text variant="textMedium" mb="0.4rem">
            {t('subTitleNotifications')}
          </Text>
          <ToggleButton
            label={t('muteButton')}
            id="mute-user"
            checked={isMuted}
            // onClick={() => toggleMute(userId)}
          />
        </>
      )}
      {sidePageChatId && (
        <ButtonsContainer>
          {!isOneToOneChat && (
            <Button
              marginTop="3.2rem"
              display="block"
              onClick={() =>
                openModal({
                  content: (
                    <DeleteChatModal
                      chatId={sidePageChatId}
                      navigateFn={() => navigate(routes.users)}
                    />
                  ),
                })
              }
              plain
              variant="warning"
              preFix={<LogoutIcon />}
            >
              {t('deleteChat')}
            </Button>
          )}
        </ButtonsContainer>
      )}
    </>
  )
}
