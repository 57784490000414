import { AddChannelMembersModal } from '@modules/modals/add-channel-members'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { MaxWidthProps, SpaceProps } from 'styled-system'
import { Button, Container, Heading, ImgContainer, PlusIcon, Text, useModal } from 'ui'

interface EmptyChannelMembersProps extends SpaceProps, MaxWidthProps {
  name?: string
  id?: string
}

export const EmptyChannelMembers: FC<EmptyChannelMembersProps> = ({ name, id, ...stylesProps }) => {
  const { openModal } = useModal()
  const { t } = useTranslation(['create-new', 'modal-add-channel-members'])

  return (
    <Container textAlign="center" {...stylesProps}>
      <ImgContainer mb="3.2rem">
        <img src="/img/onboarding-2.svg" alt="onboarding-2" width={440} height={234} />
      </ImgContainer>
      <Heading variant="h2" as="h2" fontSize="2.2rem" mb="0.8rem" lineClamp={2}>
        {t('create-new:emptyChannelMembers.heading', {
          channelName: name,
        })}
      </Heading>
      <Text mb="3.2rem">{t('create-new:emptyChannelMembers.info')}</Text>
      <Button
        width="20rem"
        preFix={<PlusIcon />}
        onClick={() =>
          name &&
          id &&
          openModal({
            content: <AddChannelMembersModal channelName={name} channelId={id} />,
            maxWidth: '62rem',
          })
        }
      >
        {t('create-new:emptyChannelMembers.addBtn')}
      </Button>
    </Container>
  )
}
