import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetChannelList } from '@api/chats/hooks/use-get-channel-list'
import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { useToggleMute } from '@api/chats/hooks/use-toggle-mute-chat'
import { IconButton } from '@components/icon-button'
import { useFormattedChatLanguages } from '@hooks/use-chat-languages'
import { useSidePage } from '@hooks/use-side-page'
import { useWorkspace } from '@hooks/use-workspace'
import { DeleteChannelModal } from '@modules/modals/delete-channel'
import { LeaveChannelModal } from '@modules/modals/leave-channel'
import { EditType } from '@modules/side-page/details'
import { routes as chatsRoutes } from '@routes/chats/routes'
import { routes } from '@routes/flow/routes'
import { getFeatureFlag } from '@utils/flags-manager'
import { getChatName } from '@utils/get-chat-name'
import { isChat, isGeneralChannel } from '@utils/get-chat-type'
import { isNativeChatsApp } from '@utils/is-native-chats-app'
import { notificationsManager } from '@utils/notifications'
import { format } from 'date-fns'
import React, { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Text,
  Button,
  Container,
  EditIcon,
  ToggleButton,
  TrashcanIcon,
  useModal,
  LogoutIcon,
  ScrollContainer,
} from 'ui'
import { ButtonsContainer, ItemContainer } from './styled'

interface AboutProps {
  setEditType: Dispatch<SetStateAction<EditType | null>>
}

export const About: FC<AboutProps> = ({ setEditType }) => {
  const { sidePageChatId } = useSidePage()
  const navigate = useNavigate()
  const { workspaceId } = useWorkspace()
  const { channels } = useGetChannelList()
  const { meData } = useGetMe()
  const { data: chatDetails } = useGetChatDetails(sidePageChatId || undefined)
  const { openModal } = useModal()
  const { t } = useTranslation(['details', 'modal-delete', 'modal-leave'])
  const toggleMute = useToggleMute()

  const creator = useMemo(() => {
    if (!chatDetails) {
      return null
    }
    {
      notificationsManager.toggleNotificationsForChat(chatDetails.id, chatDetails.isMuted)
      return chatDetails.chatUsers.find(({ user }) => user.userId === chatDetails.createdBy)?.user
    }
  }, [chatDetails])

  const chatLanguages = useFormattedChatLanguages(chatDetails)

  const navigateToGeneral = () =>
    navigate(`${routes.workspaces}/${workspaceId}/${routes.channels}/${channels[0].id}`)

  const navigateToWelcom = () => navigate(`${chatsRoutes.home}`)

  const canBeEditedByUser = useMemo(() => {
    if (!chatDetails || !meData) return null
    const users = chatDetails.chatUsers
    const myUser = users.find(({ user }) => user.userId === meData.userId)
    const isMyUserInChat = !!myUser
    const isMyUserActiveInChat = !myUser?.leftChat

    return isMyUserInChat && isMyUserActiveInChat
  }, [chatDetails, meData])

  if (!chatDetails || !meData) {
    return null
  }

  return (
    <ScrollContainer
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      hideScrollbar
    >
      <Container>
        {!isChat(chatDetails) && (
          <>
            <ItemContainer>
              <Text variant="textMedium" mb="0.4rem">
                {t('name')}
              </Text>
              <Container display="flex" justifyContent="space-between" alignItems="flex-end">
                <Text>{getChatName(chatDetails, t)}</Text>
                {canBeEditedByUser && !isGeneralChannel(chatDetails) && (
                  <IconButton onClick={() => setEditType(EditType.channelName)}>
                    <EditIcon aria-label={t('editName')} />
                  </IconButton>
                )}
              </Container>
            </ItemContainer>
            {chatDetails.description && (
              <ItemContainer>
                <Text variant="textMedium" mb="0.4rem">
                  {t('description')}
                </Text>
                <Container display="flex" justifyContent="space-between" alignItems="flex-end">
                  <Text color={!chatDetails.description ? 'greyDark' : 'default'}>
                    {chatDetails.description || 'Add description'}
                  </Text>
                  {getFeatureFlag('showOnProd') && (
                    <IconButton onClick={() => setEditType(EditType.channelDescription)} ml="auto">
                      <EditIcon aria-label={t('editDescription')} />
                    </IconButton>
                  )}
                </Container>
              </ItemContainer>
            )}
          </>
        )}
        {getFeatureFlag('showOnProd') && (
          <ItemContainer>
            <Text variant="textMedium" mb="0.4rem">
              {t('notifications')}
            </Text>
            <ToggleButton
              label={isChat(chatDetails) ? t('muteChatToggle') : t('muteChannelToggle')}
              id="mute"
              checked={chatDetails?.isMuted}
              onClick={toggleMute}
            />
          </ItemContainer>
        )}
        {!isNativeChatsApp() && (
          <ItemContainer>
            <Text variant="textMedium" mb="0.4rem">
              {t('teammatesLanguages')}
            </Text>
            <Text pr="2rem">{chatLanguages}</Text>
          </ItemContainer>
        )}
        <ItemContainer>
          <Text variant="textMedium" mb="0.4rem">
            {t('created')}
          </Text>
          <Text pr="2rem">
            <>
              {creator?.firstName} {creator?.lastName} {` `}
              {t('on')} {format(new Date(chatDetails.createdAt), 'MMMM d, yyyy')}
            </>
          </Text>
        </ItemContainer>
      </Container>
      {!isChat(chatDetails) && !isGeneralChannel(chatDetails) && (
        <ButtonsContainer>
          {canBeEditedByUser && (
            <Button
              display="block"
              onClick={() =>
                openModal({
                  content: (
                    <LeaveChannelModal
                      channelId={chatDetails.id}
                      name={chatDetails.name}
                      myUserId={meData.userId}
                      navigateFn={isNativeChatsApp() ? navigateToWelcom : navigateToGeneral}
                    />
                  ),
                })
              }
              plain
              variant="warning"
              preFix={<LogoutIcon />}
            >
              {t('leaveChannel')}
            </Button>
          )}
          {!isNativeChatsApp() && (
            <Button
              display="block"
              onClick={() =>
                openModal({
                  content: (
                    <DeleteChannelModal
                      id={chatDetails.id}
                      name={chatDetails.name}
                      membersNumber={chatDetails.chatUsers.length}
                    />
                  ),
                })
              }
              plain
              variant="warning"
              preFix={<TrashcanIcon />}
            >
              {t('deleteChannel')}
            </Button>
          )}
        </ButtonsContainer>
      )}
    </ScrollContainer>
  )
}
