import { theme } from '@common/theme'
import { getFileTypeFromName } from '@utils/get-file-type-from-name'
import React, { ElementType, FC } from 'react'
import styled from 'styled-components'
import {
  AudioIcon,
  CodeIcon,
  Container,
  FileIcon,
  PdfIcon,
  PlayIcon,
  SpinnerDarkAnimation,
  SpinnerLightAnimation,
} from 'ui'

interface PreviewContainerProps {
  isLoading: boolean
  color: string
}

interface PreviewProps extends PreviewContainerProps {
  name: string
  src: string
  type?: FileType
}

export type FileType =
  | 'jpg'
  | 'jpeg'
  | 'png'
  | 'webp'
  | 'svg'
  | 'mp4'
  | 'html'
  | 'mp3'
  | 'pdf'
  | 'doc'
  | 'docx'
  | 'txt'
  | 'other'

const SPINNER_SIZE = 24

const getPreviewSettings = (type: FileType, src?: string, name?: string) => {
  const fileTypeStyles: Record<FileType, { backgroundColor: string; elementType: ElementType }> = {
    jpg: { backgroundColor: 'transparent', elementType: 'img' },
    jpeg: { backgroundColor: 'transparent', elementType: 'img' },
    png: { backgroundColor: 'transparent', elementType: 'img' },
    webp: { backgroundColor: 'transparent', elementType: 'img' },
    svg: { backgroundColor: theme.colors.yellow, elementType: FileIcon },
    mp4: { backgroundColor: '#ED7E0C', elementType: PlayIcon },
    mp3: { backgroundColor: theme.colors.redDark, elementType: AudioIcon },
    html: { backgroundColor: '#006D84', elementType: CodeIcon },
    pdf: { backgroundColor: theme.colors.green, elementType: PdfIcon },
    doc: { backgroundColor: '#ED7E0C', elementType: FileIcon },
    docx: { backgroundColor: '#ED7E0C', elementType: FileIcon },
    txt: { backgroundColor: '#ED7E0C', elementType: FileIcon },
    other: { backgroundColor: theme.colors.primary, elementType: FileIcon },
  }

  const { backgroundColor, elementType } = fileTypeStyles[type]
  const previewElement =
    elementType === 'img' ? <img src={src} alt={name} /> : React.createElement(elementType)

  return {
    backgroundColor,
    previewElement,
  }
}
export const Preview: FC<PreviewProps> = ({ color, isLoading, src, name, type }) => {
  const typeFromName: FileType = getFileTypeFromName(name) // TODO: we should get file type from metadata
  const { backgroundColor, previewElement } = getPreviewSettings(type ?? typeFromName, src, name)

  return (
    <PreviewContainer backgroundColor={backgroundColor} color={color} isLoading={isLoading}>
      {isLoading ? (
        color === 'light' ? (
          <SpinnerLightAnimation size={SPINNER_SIZE} />
        ) : (
          <SpinnerDarkAnimation size={SPINNER_SIZE} />
        )
      ) : (
        previewElement
      )}
    </PreviewContainer>
  )
}

const PreviewContainer = styled(Container)<PreviewContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 0.6rem;
  overflow: hidden;
  flex: 0 0 auto;

  ${({ isLoading, color, theme }) =>
    isLoading &&
    `
      border: 1px solid ${color === 'light' ? 'rgba(255,255,255,0.4)' : theme.colors.grey};

    `}
  & svg {
    fill: ${({ theme }) => theme.colors.white};
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
