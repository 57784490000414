import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FlexProps, position, PositionProps, WidthProps } from 'styled-system'
import { CloseBoldIcon, Container, DownloadIcon, Text, Tooltip } from 'ui'
import { FileType, Preview } from './preview'

interface AttachmentProps extends WidthProps, FlexProps {
  color?: ColorVariant
  src?: string
  name?: string
  size?: string | 0
  isLoading?: boolean
  type?: FileType
  onRemove?: VoidFunction
  onDownload?: VoidFunction
  onClick?: VoidFunction
}

type ColorVariant = 'default' | 'light'

interface AttachmentButtonProps extends PositionProps {
  color?: ColorVariant
}

export const AttachmentItem: FC<AttachmentProps> = ({
  color = 'default',
  size,
  src,
  name,
  isLoading = false,
  onRemove,
  onDownload,
  onClick,
}) => {
  const attachmentElement = onClick ? 'button' : 'div'
  const { t } = useTranslation('attachments')

  return (
    //TODO: Later this Container below should help to handle responsive widths in message bubbles, for now we use fixed value
    <Container width="20.8rem">
      <AttachmentContainer
        as={attachmentElement}
        type={onClick ? 'button' : undefined}
        color={color}
        p="0.8rem"
        pr={onRemove || onDownload ? '3.6rem' : '1.2rem'}
        onClick={onClick}
      >
        {name && src && <Preview name={name} isLoading={isLoading} color={color} src={src} />}
        <InnerContainer>
          {name && (
            <Tooltip text={name} placement="top">
              <Name variant="textMedium" color={color}>
                {name}
              </Name>
            </Tooltip>
          )}
          {size && (
            <FileSize variant="smallTextRegular" color={color}>
              {size}
            </FileSize>
          )}
        </InnerContainer>
        {onRemove && (
          <AttachmentButton
            position="absolute"
            top="0.7rem"
            right="0.7rem"
            color={color}
            onClick={onRemove}
            aria-label={t('remove')}
          >
            <CloseBoldIcon />
          </AttachmentButton>
        )}
        {onDownload && (
          <DownloadButton
            position="absolute"
            top="1.3rem"
            right="0.7rem"
            color={color}
            onClick={onRemove}
            aria-label={t('download')}
          >
            <DownloadIcon />
          </DownloadButton>
        )}
      </AttachmentContainer>
    </Container>
  )
}

const AttachmentButton = styled.button<AttachmentButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color ${({ theme }) => theme.transitionTimes.short};
  ${({ color, theme }) => `
    color: ${color === 'light' ? theme.colors.primaryLighter : theme.colors.greyDark};
    padding: 0.5rem;

    &:hover {
      color: ${color === 'light' ? theme.colors.white : theme.colors.primary};
    }
  `}

  ${position}
`

const DownloadButton = styled(AttachmentButton)`
  opacity: 0;
  transition: opacity ${({ theme }) => theme.transitionTimes.short};
`

const AttachmentContainer = styled(Container)<{ color: ColorVariant }>`
  display: flex;
  text-align: left;
  overflow: hidden;
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  border-radius: 0.8rem;
  ${({ color, theme }) =>
    color === 'light' &&
    `
      border-color: rgba(255, 255, 255, 0.4);
      color: ${theme.colors.white}
    `}
  &:hover {
    ${DownloadButton} {
      opacity: 1;
    }
  }
`

const FileSize = styled(Text)<{ color: ColorVariant }>`
  color: ${({ theme }) => theme.colors.greySuperDark};

  ${({ color, theme }) =>
    color === 'light' &&
    `
      color: ${theme.colors.white};
      opacity: 0.7;
    `}
`

// Truncates file name without setting max-width or width
const Name = styled(Text)<{ color: ColorVariant }>`
  ${({ color, theme }) =>
    color === 'light' &&
    `
      color: ${theme.colors.white};
    `}
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: break-spaces;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: default;
`

const InnerContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
  margin-left: 0.8rem;
`
