import { FileType } from '@modules/attachment-item/preview'

export const getFileTypeFromName = (name: string): FileType => {
  const extension = name.split('.').pop()?.toLowerCase() || 'other'
  const extensionMap: Record<string, FileType> = {
    jpg: 'jpg',
    jpeg: 'jpg',
    webp: 'webp',
    png: 'png',
    svg: 'svg',
    mp4: 'mp4',
    html: 'html',
    mp3: 'mp3',
    pdf: 'pdf',
    doc: 'doc',
    docx: 'docx',
    txt: 'txt',
  }
  return extensionMap[extension] || 'other'
}
