import { AttachmentButton } from '@components/attachment-button'
import { EmojiPopover } from '@components/emoji-popover'
import { useAttachmentsUploadingContext } from '@contexts/attachments-uploading-provider'
import { AttachmentItem } from '@modules/attachment-item'
import { bytesToSize } from '@utils/bytes-to-size'
import { isNativeChatsApp } from '@utils/is-native-chats-app'
import { setCursorPosition } from '@utils/set-cursor-position'
import { EmojiClickData } from 'emoji-picker-react'
import React, { forwardRef, useState, useRef, useEffect, MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { Container, EmojiIcon, SendIcon, Text, useClickOutside, Tooltip } from 'ui'
import { MessageInputMode } from '.'
import {
  ExpandingInput,
  InputButton,
  InputScrollContainer,
  InputButtons,
  SendButton,
  InputBottom,
  InputWrapper,
  AttachmentsContainer,
} from './styled'

const ATTACHMENTS_LIMIT = 10

export interface SendGridProps {
  contentEditableInputText: string
  cursorPosition: number
  handleOnChangeText: (target: HTMLDivElement) => void
  onClickSend: () => void
  isDisabled?: boolean
  hasReachedLimit?: boolean
  charsLeftText?: string
  onEmojiClick: ({ emoji }: EmojiClickData, event: MouseEvent, onClose: any) => void
}

export const SendGrid = forwardRef<HTMLDivElement | null, SendGridProps>(
  (
    {
      contentEditableInputText,
      cursorPosition,
      handleOnChangeText,
      onClickSend,
      isDisabled,
      hasReachedLimit,
      charsLeftText,
      onEmojiClick,
    },
    ref
  ) => {
    const { t } = useTranslation(['chat'])
    const theme = useTheme()
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const inputRef = useRef<null | HTMLDivElement>(null)
    useClickOutside(inputRef, () => setIsFocused(false))
    const { onImageRemove } = useAttachmentsUploadingContext()

    const { imageList } = useAttachmentsUploadingContext()

    const onFocusInputWrapper = () => {
      setIsFocused(true)
    }

    const focusInput = () => {
      // googled it, and it is possible to take a current in DIV ref element, but TS still complains
      // @ts-ignore
      ref?.current?.focus()
    }

    useEffect(() => {
      const target = ref as MutableRefObject<HTMLDivElement>
      if (target.current) setCursorPosition(cursorPosition, target.current)
    }, [contentEditableInputText])

    return (
      <InputWrapper onFocus={onFocusInputWrapper} ref={inputRef} isFocused={isFocused}>
        <InputScrollContainer messageInputMode={MessageInputMode.SEND}>
          <ExpandingInput
            // https://www.jsdocs.io/package/@types/react#HTMLAttributes.contentEditable
            // @ts-ignore
            contentEditable="plaintext-only"
            placeholder={t('messageInput.placeholder')}
            onInput={(e) => {
              handleOnChangeText(e.currentTarget)
            }}
            ref={ref}
            suppressContentEditableWarning={true}
          >
            {contentEditableInputText}
          </ExpandingInput>
          {imageList.length > 0 && (
            <AttachmentsContainer p="1.2rem">
              {imageList.map((attachment, index) => (
                <AttachmentItem
                  key={index}
                  name={attachment?.file?.name}
                  src={attachment.dataURL}
                  size={attachment.file?.size && bytesToSize(attachment.file?.size)}
                  onRemove={() => onImageRemove(index)}
                />
              ))}
            </AttachmentsContainer>
          )}
        </InputScrollContainer>
        <InputBottom onClick={focusInput}>
          <InputButtons>
            <EmojiPopover
              onEmojiClick={onEmojiClick}
              floatProps={{
                offset: { crossAxis: -15 },
              }}
              pb="4.5rem"
            >
              <InputButton type="button" aria-label={t('messageInput.addEmoji')}>
                <EmojiIcon />
              </InputButton>
            </EmojiPopover>
            <Tooltip text={t('tooltip.attachmentIcon', { count: ATTACHMENTS_LIMIT })}>
              <Container>
                <AttachmentButton />
              </Container>
            </Tooltip>
          </InputButtons>
          <Container display="flex" alignItems="center">
            {charsLeftText && (
              <Text
                color={hasReachedLimit ? theme.colors.red : theme.colors.greyDark}
                variant="smallTextRegular"
                mr="2rem"
              >
                {charsLeftText}
              </Text>
            )}
            <SendButton
              variant="primary"
              circle
              onClick={onClickSend}
              aria-label={t('sendMessage')}
              disabled={isDisabled}
              icon={<SendIcon />}
            />
          </Container>
        </InputBottom>
      </InputWrapper>
    )
  }
)

SendGrid.displayName = 'SendGrid'
