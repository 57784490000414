import { ChatUser } from '@api/chats/types/chat-user'

export const getChatUserNames = (
  deactivatedAccountStr: string,
  users: ChatUser[],
  myUserId?: string
) => {
  if (!users || !myUserId) return ''
  return users
    .filter(({ user: { userId } }) => userId !== myUserId)
    .map(({ user: { isDeleted, firstName, lastName } }) =>
      isDeleted ? deactivatedAccountStr : `${firstName} ${lastName}`
    )
    .join(', ')
}
