/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddChatUsersRequest } from '../models/AddChatUsersRequest';
import type { ChannelInvitationLinkDto } from '../models/ChannelInvitationLinkDto';
import type { ChatResponse } from '../models/ChatResponse';
import type { ChatsListResponse } from '../models/ChatsListResponse';
import type { ChatUserResponse } from '../models/ChatUserResponse';
import type { ChatUsersResponse } from '../models/ChatUsersResponse';
import type { CreateChatRequest } from '../models/CreateChatRequest';
import type { GetBlockedUsersResponse } from '../models/GetBlockedUsersResponse';
import type { RemoveChatUsersRequest } from '../models/RemoveChatUsersRequest';
import type { SetChatAvatarRequest } from '../models/SetChatAvatarRequest';
import type { TransformChatRequest } from '../models/TransformChatRequest';
import type { UpdateChatInfoRequest } from '../models/UpdateChatInfoRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChatsService {

    /**
     * Gets chat details.
     * @param chatId
     * @returns ChatResponse Chat details
     * @throws ApiError
     */
    public static getApiV2Chats(
        chatId: string,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/chats/{chatId}',
            path: {
                'chatId': chatId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Chat not found`,
            },
        });
    }

    /**
     * Chat users
     * @param chatId
     * @returns ChatUsersResponse Success
     * @throws ApiError
     */
    public static getApiV2ChatsUsers(
        chatId: string,
    ): CancelablePromise<ChatUsersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/chats/{chatId}/users',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * Chat user
     * @param chatId
     * @param userId
     * @returns ChatUserResponse Success
     * @throws ApiError
     */
    public static getApiV2ChatsUsers1(
        chatId: string,
        userId: string,
    ): CancelablePromise<ChatUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/chats/{chatId}/users/{userId}',
            path: {
                'chatId': chatId,
                'userId': userId,
            },
        });
    }

    /**
     * Get blocked users in chat
     * @param chatId
     * @returns GetBlockedUsersResponse Success
     * @throws ApiError
     */
    public static getApiV2ChatsBlockedUsers(
        chatId: string,
    ): CancelablePromise<GetBlockedUsersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/chats/{chatId}/blocked-users',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * Block chat user
     * @param chatId
     * @param userId
     * @returns ChatUserResponse Success
     * @throws ApiError
     */
    public static postApiV2ChatsUsersBlock(
        chatId: string,
        userId: string,
    ): CancelablePromise<ChatUserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/chats/{chatId}/users/{userId}/block',
            path: {
                'chatId': chatId,
                'userId': userId,
            },
        });
    }

    /**
     * Unblock chat user
     * @param chatId
     * @param userId
     * @returns ChatUserResponse Success
     * @throws ApiError
     */
    public static postApiV2ChatsUsersUnblock(
        chatId: string,
        userId: string,
    ): CancelablePromise<ChatUserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/chats/{chatId}/users/{userId}/unblock',
            path: {
                'chatId': chatId,
                'userId': userId,
            },
        });
    }

    /**
     * Mute chat user
     * @param chatId
     * @param userId
     * @returns ChatUserResponse Success
     * @throws ApiError
     */
    public static postApiV2ChatsUsersMute(
        chatId: string,
        userId: string,
    ): CancelablePromise<ChatUserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/chats/{chatId}/users/{userId}/mute',
            path: {
                'chatId': chatId,
                'userId': userId,
            },
        });
    }

    /**
     * Unmute chat user
     * @param chatId
     * @param userId
     * @returns ChatUserResponse Success
     * @throws ApiError
     */
    public static postApiV2ChatsUsersUnmute(
        chatId: string,
        userId: string,
    ): CancelablePromise<ChatUserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/chats/{chatId}/users/{userId}/unmute',
            path: {
                'chatId': chatId,
                'userId': userId,
            },
        });
    }

    /**
     * List chats
     * @param pageNumber
     * @param pageSize
     * @param order
     * @returns ChatsListResponse Success
     * @throws ApiError
     */
    public static getApiV2ChatsList(
        pageNumber?: number,
        pageSize?: number,
        order: string = 'DESC',
    ): CancelablePromise<ChatsListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/chats/list',
            query: {
                'pageNumber': pageNumber,
                'pageSize': pageSize,
                'order': order,
            },
        });
    }

    /**
     * Create chat
     * @param requestBody
     * @returns ChatResponse Success
     * @throws ApiError
     */
    public static postApiV2Chats(
        requestBody?: CreateChatRequest,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/chats',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Update chat
     * @param chatId
     * @param requestBody
     * @returns ChatResponse Success
     * @throws ApiError
     */
    public static patchApiV2ChatsUpdateInfo(
        chatId: string,
        requestBody?: UpdateChatInfoRequest,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v2/chats/{chatId}/update-info',
            path: {
                'chatId': chatId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Set chat avatar
     * @param chatId
     * @param requestBody
     * @returns ChatResponse Success
     * @throws ApiError
     */
    public static patchApiV2ChatsAvatar(
        chatId: string,
        requestBody?: SetChatAvatarRequest,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v2/chats/{chatId}/avatar',
            path: {
                'chatId': chatId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Remove chat avatar
     * @param chatId
     * @returns ChatResponse Success
     * @throws ApiError
     */
    public static patchApiV2ChatsAvatarRemove(
        chatId: string,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v2/chats/{chatId}/avatar-remove',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * Transform chat to channel
     * @param chatId
     * @param requestBody
     * @returns ChatResponse Success
     * @throws ApiError
     */
    public static postApiV2ChatsTransform(
        chatId: string,
        requestBody?: TransformChatRequest,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/chats/{chatId}/transform',
            path: {
                'chatId': chatId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Add users to chat
     * @param chatId
     * @param requestBody
     * @returns ChatResponse Success
     * @throws ApiError
     */
    public static postApiV2ChatsAddUsers(
        chatId: string,
        requestBody?: AddChatUsersRequest,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/chats/{chatId}/add-users',
            path: {
                'chatId': chatId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Remove users from chat
     * @param chatId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiV2ChatsRemoveUsers(
        chatId: string,
        requestBody?: RemoveChatUsersRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v2/chats/{chatId}/remove-users',
            path: {
                'chatId': chatId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param chatId
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV2ChatsMute(
        chatId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/chats/{chatId}/mute',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * @param chatId
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV2ChatsUnmute(
        chatId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/chats/{chatId}/unmute',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * @param chatId
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV2ChatsBlock(
        chatId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/chats/{chatId}/block',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * @param chatId
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV2ChatsUnblock(
        chatId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/chats/{chatId}/unblock',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * @param chatId
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiV2ChatsLeave(
        chatId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v2/chats/{chatId}/leave',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * Get channel invitation link
     * @param chatId
     * @returns ChannelInvitationLinkDto Success
     * @throws ApiError
     */
    public static getApiV2ChatsInviteLink(
        chatId: string,
    ): CancelablePromise<ChannelInvitationLinkDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/chats/{chatId}/invite/link',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * Accept channel invitation
     * @param code
     * @returns ChatResponse Success
     * @throws ApiError
     */
    public static getApiV2ChatsInviteAccept(
        code?: string,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/chats/invite/accept',
            query: {
                'code': code,
            },
        });
    }

}
