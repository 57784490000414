import { User } from '@api/account/types/user'
import { AttachmentResponse } from '@api/messages/types/attachment-response'
import { ImgPreview as ImgPreviewNfc } from '@modules/native-chats/img-preview'
import { isNativeChatsApp } from '@utils/is-native-chats-app'
import { IMG_ZOOMED_SCALE } from '@utils/variables'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PositionProps, position } from 'styled-system'
import { ChevronUpIcon, Container, useModal } from 'ui'
import { useInteraction } from '../../../shared/lib/hooks/use-interaction'
import { GalleryHeader } from './header'
import { ImgPreview } from './img-preview'

interface GalleryModalProps {
  attachments: AttachmentResponse[]
  conversationId: string
  initialIndex: number
  sender: User
  createdAt?: Date
}

export const GalleryModal: FC<GalleryModalProps> = ({
  attachments,
  conversationId,
  initialIndex,
  sender,
  createdAt,
}) => {
  const { t } = useTranslation('attachments')
  const { closeModal } = useModal()
  const [currentIndex, setCurrentIndex] = useState<number>(initialIndex)
  const [isZoomed, setIsZoomed] = useState<boolean>(false)
  const { isDragging, scale, setOffset, interactionRef } = useInteraction<HTMLDivElement>()

  const totalAttachments = attachments.length
  const showButtons = totalAttachments > 1

  const handlePrev = useCallback(() => {
    setCurrentIndex((prevSlide) => (prevSlide - 1 + totalAttachments) % totalAttachments)
  }, [totalAttachments])

  const handleNext = useCallback(() => {
    setCurrentIndex((prevSlide) => (prevSlide + 1) % totalAttachments)
  }, [totalAttachments])

  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        handlePrev()
      }
      if (e.key === 'ArrowRight') {
        handleNext()
      }
      if (e.key === 'Escape') {
        closeModal()
      }
    },
    [handlePrev, handleNext]
  )

  // Two states zoom on click
  const handleOnZoom = () => {
    if (!isDragging) {
      const newScale = isZoomed ? 1 : IMG_ZOOMED_SCALE
      setOffset({ dx: 0, dy: 0, scale: newScale })
      setIsZoomed(newScale === IMG_ZOOMED_SCALE)
    }
  }

  useEffect(() => {
    if (scale !== 1) {
      setIsZoomed(true)
    }
  }, [scale])

  useEffect(() => {
    window.addEventListener('keydown', handleOnKeyDown)
    return () => {
      window.removeEventListener('keydown', handleOnKeyDown)
    }
  }, [handleOnKeyDown])

  return (
    <Container position="relative" display="flex" flexDirection="column" height="100%" width="100%">
      <GalleryHeader
        attachment={attachments[currentIndex]}
        sender={sender}
        createdAt={createdAt}
        onClose={closeModal}
      />
      {showButtons && (
        <GalleryButton aria-label={t('prevMediaItem')} left="3.2rem" onClick={handlePrev}>
          <ChevronUpIcon transform="rotate(-90)" />
        </GalleryButton>
      )}
      <GalleryContainer>
        {isNativeChatsApp() ? (
          <ImgPreviewNfc
            attachments={attachments}
            onZoom={handleOnZoom}
            currentIndex={currentIndex}
            ref={interactionRef}
            alt={t('image')}
          />
        ) : (
          <ImgPreview
            attachments={attachments}
            onZoom={handleOnZoom}
            conversationId={conversationId}
            currentIndex={currentIndex}
            ref={interactionRef}
            alt={t('image')}
          />
        )}
      </GalleryContainer>
      {showButtons && (
        <GalleryButton aria-label={t('nextMediaItem')} right="3.2rem" onClick={handleNext}>
          <ChevronUpIcon transform="rotate(90)" />
        </GalleryButton>
      )}
    </Container>
  )
}

const GalleryContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 9rem 8.4rem;
`

const GalleryButton = styled.button<PositionProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  width: 4rem;
  height: 4rem;
  border-radius: 10rem;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  transition: color ${({ theme }) => theme.transitionTimes.short};
  z-index: 1;
  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
  ${position}
`
