import { PersonalProfile } from '@api/account/types/personal-profile'
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit'
import { Conversation } from './conversation'
import { ConversationType } from './conversation-type'
import { conversationsEntityAdapter, getConversationsInitialState } from './entity-adapter'
import { parseConversationUserLeft } from './helpers/parse-conversation-user-left'
import { parseUser } from './helpers/parse-user'
import { SyncConversationsPayload } from './sync-conversations'
import { LeaveConversation } from './types/leave-conversation'
import { UpdateConversation } from './types/update-conversation'

const conversationsAdapter = createEntityAdapter<Conversation>()

export const { selectAll: selectConversations, selectById: selectConversation } =
  conversationsAdapter.getSelectors((state: EntityState<Conversation>) => state)

export const selectConversationByType = (type: ConversationType) =>
  createSelector([selectConversations], (allConversations) => {
    return allConversations.find((conversation) => conversation.conversationType === type)
  })

export interface ConversationsState extends EntityState<Conversation> {
  lastConversationId?: string
  lastConversationTimestamp?: string
}

const initialState: ConversationsState = {
  lastConversationId: undefined,
  lastConversationTimestamp: undefined,
  ...getConversationsInitialState(),
}

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    syncConversations: (_state, { payload }: PayloadAction<SyncConversationsPayload>) => {
      return {
        lastChatIdInBatch: payload.lastConversationId,
        lastTimestamp: payload.lastConversationTimestamp,
        ...conversationsEntityAdapter.setAll(getConversationsInitialState(), payload.conversations),
      }
    },
    updateConversation: (state, action: PayloadAction<UpdateConversation>) => {
      const { id, updatedData } = action.payload
      if (!updatedData) {
        return
      }

      conversationsAdapter.updateOne(state, {
        id,
        changes: updatedData,
      })
    },
    leaveConversation: (state, action: PayloadAction<LeaveConversation>) => {
      const { id, myUserId } = action.payload
      const conversation = selectConversation(state, id)
      if (!conversation || !myUserId) return
      const updatedMyConversationUser = parseConversationUserLeft(conversation, myUserId)

      if (updatedMyConversationUser) {
        conversationsAdapter.updateOne(state, {
          id,
          changes: {
            chatUsers: updatedMyConversationUser,
          },
        })
      }
    },
    joinConversation: (
      state,
      action: PayloadAction<{ conversation: Conversation; newUser: PersonalProfile }>
    ) => {
      const { conversation, newUser } = action.payload
      if (!conversation || !newUser) {
        return
      }
      const myUser = parseUser(newUser)
      const existingConversation = state.entities[conversation.id]

      if (!existingConversation) {
        conversationsAdapter.addOne(state, {
          ...conversation,
          chatUsers: [myUser], // Initialize with the new user
        })
      } else {
        const myUserInConversation = existingConversation.chatUsers.find(
          (user) => user.user.userId === newUser.userId
        )

        if (myUserInConversation) {
          // If the user exists, update leftChat to false (user was already in conversation before, but he left), otherwise add him
          myUserInConversation.leftChat = false
        } else {
          existingConversation.chatUsers.push(myUser)
        }

        conversationsAdapter.updateOne(state, {
          id: conversation.id,
          changes: {
            chatUsers: existingConversation.chatUsers,
          },
        })
      }
    },
    deleteConversation: (state, action: PayloadAction<{ id: string }>) => {
      conversationsAdapter.removeOne(state, action.payload.id)
    },
    markConversationAsUnread: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload
      const conversation = selectConversation(state, id)
      if (!conversation) {
        return
      }
      conversationsAdapter.updateOne(state, {
        id,
        changes: {
          unreadMessagesCount: conversation.unreadMessagesCount + 1,
        },
      })
    },
    markConversationAsRead: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload
      conversationsAdapter.updateOne(state, {
        id,
        changes: {
          unreadMessagesCount: 0,
        },
      })
    },
  },
})

export default conversationsSlice.reducer

export const {
  syncConversations,
  updateConversation,
  leaveConversation,
  joinConversation,
  deleteConversation,
  markConversationAsRead,
  markConversationAsUnread,
} = conversationsSlice.actions
