import { Chat } from '@api/chats/types/chat'
import { ChatUser } from '@api/chats/types/chat-user'
import { TFunction } from 'i18next'
import { isChat } from './get-chat-type'

export const formatConversationName = (chat: Chat, myUserId: string, t: TFunction): string => {
  let result = ''
  const users = chat.chatUsers || []
  const usersSendingToMe: ChatUser[] = users?.filter(({ user: { userId } }) => userId !== myUserId)

  if (!isChat(chat)) {
    return chat.name
  }

  if (users.length === 1 && users[0].user.userId === myUserId) {
    //  Personal chat
    const { firstName, lastName } = users[0].user
    result = `${firstName} ${lastName} (${t('chatName.you')})`
  } else if (usersSendingToMe.length === 1) {
    // 1 to 1 chat
    const { isDeleted, firstName, lastName } = usersSendingToMe[0].user
    result = isDeleted ? t('chatName.deactivatedAccount') : `${firstName} ${lastName}`
  } else if (usersSendingToMe.length > 1) {
    //  2 and more chat users excl. my user
    const firstTwoUsers = usersSendingToMe
      .slice(0, 2)
      .map(({ user: { isDeleted, firstName } }) =>
        isDeleted ? t('chatName.deactivatedAccount') : firstName
      )
      .join(', ')
    const remainingCount = usersSendingToMe.length - 2

    result = `${firstTwoUsers}${
      remainingCount > 0
        ? ` ${t(remainingCount === 1 ? 'chatName.otherUser' : 'chatName.otherUsers', {
            count: remainingCount,
          })}`
        : ''
    }`
  }

  return result
}
