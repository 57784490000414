export const filterByFields = <T,>(items: T[], searchString: string, keys: (keyof T)[]) => {
  return (
    items.filter((item) => {
      const combinedFields = keys
        .map((key) => String(item[key])) // Convert each specified key to a string
        .join(' ')
        .toLowerCase()
      return combinedFields.includes(searchString.toLowerCase())
    }) ?? []
  )
}
