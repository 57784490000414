import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { NetworkMode } from '@api/enums'
import { getChatAvatar } from '@utils/get-chat-avatar'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatConversationName } from 'src/entities/conversations/lib/format-conversation-name'
import { Conversation } from 'src/entities/conversations/model/conversation'
import { useGetUsers } from 'src/entities/teammates/api/hooks/use-get-users'
import { User } from 'src/entities/teammates/api/model/user-type'
import styled from 'styled-components'
import { Container } from 'ui'
import { MessageInfo } from './message-info'

export interface MessageItemProps {
  chat: Conversation
}

export const MessageItem: FC<MessageItemProps> = ({ chat }) => {
  const { chatUsers, lastMessage, avatar, id } = chat
  const { meData } = useGetMe(false, NetworkMode.OFFLINE_FIRST)
  const { users } = useGetUsers({ networkMode: NetworkMode.OFFLINE_FIRST })
  const [chatUser, setChatUser] = useState<User>()
  const { t } = useTranslation('chat')

  const chatName = useMemo(() => {
    if (!chat || !meData) return ''
    return formatConversationName(chat, meData.userId, t)
  }, [chat, t])

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: chatUser?.userId,
    //TODO: review Avatar types
    //@ts-ignore
    avatarId: chatUser?.avatar?.id,
  })

  useEffect(() => {
    if (chatUsers.length === 1) {
      setChatUser(chat.chatUsers[0].user)
    }
    if (chatUsers.length === 2) {
      const uniqUser = chat.chatUsers.find((chatUser) => chatUser.user.userId !== meData?.userId)
      if (uniqUser) {
        setChatUser(uniqUser.user)
      }
    }
  }, [chatUsers])

  return (
    <MessageContainer>
      {getChatAvatar({
        ...chat,
        avatar: avatar,
        label: chatName?.slice(0, 1),
        size: '3.6rem',
        borderRadius: '0.6rem',
        iconSize: 24,
        id: id,
        workspaceUsers: users,
        me: meData,
        chatUsers,
        imgURL: usersAvatarUrl,
        isAvatarExists: enabled,
      })}
      <MessageInfo
        name={chatName}
        lastMessage={lastMessage.originalMessage}
        lastModifiedAt={lastMessage.updatedAt}
      />
    </MessageContainer>
  )
}

export const MessageContainer = styled(Container)`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`
