import { createSlice } from '@reduxjs/toolkit'
import { usersEntityAdapter, usersInitialState } from './entity-adapter'

const usersSlice = createSlice({
  name: 'users',
  initialState: usersInitialState,
  reducers: {
    setUser: usersEntityAdapter.setOne,
    setUsers: usersEntityAdapter.setMany,
  },
})

export const { setUser, setUsers } = usersSlice.actions
export default usersSlice.reducer
