import { ConversationUser } from 'src/entities/conversations/model/conversation'

export const getChatUserNames = (
  deactivatedAccountStr: string,
  users: ConversationUser[],
  myUserId?: string
) => {
  if (!users || !myUserId) return ''
  return users
    .filter(({ user: { userId } }) => userId !== myUserId)
    .map(({ user: { isDeleted, firstName, lastName } }) =>
      isDeleted ? deactivatedAccountStr : `${firstName} ${lastName}`
    )
    .join(', ')
}
