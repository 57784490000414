import { DateDivider } from '@components/date-divider'
import { GeneralMessageProps, Message, MessageVariant } from '@components/message'
import { useInterfaceLanguage } from '@hooks/use-interface-language'
import { MessageDate } from '@modules/conversation-body/types'
import { localeUS, localeTh } from '@modules/conversation-body/utils'
import { Language } from '@utils/locales'
import { formatRelative } from 'date-fns'
import React, { FC, useRef, useEffect, useState } from 'react'
import { Container } from 'ui'

interface MessageListWithDateDividerProps {
  list: (GeneralMessageProps | MessageDate)[]
  showAvatar?: boolean
  isNearBottom: boolean
}

const LOCALES: Record<Language, Locale> = {
  en: localeUS,
  th: localeTh,
}

export const MessageListWithDateDivider: FC<MessageListWithDateDividerProps> = ({
  list,
  showAvatar,
  isNearBottom,
}) => {
  const { interfaceLanguage } = useInterfaceLanguage()
  const messageEndRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (isNearBottom) {
      scrollToBottom()
    }
  }, [list, isNearBottom])

  return (
    <div style={{ overflowY: 'auto', height: '100%' }}>
      {list.map((message: GeneralMessageProps | MessageDate, index) => {
        if (message.type === 'day' && message.date) {
          const date = new Date(message.date)
          const now = new Date()
          const formattedDate = formatRelative(date, now, {
            locale: LOCALES[interfaceLanguage.value as Language] || localeUS,
          })
          return <DateDivider key={index} date={formattedDate} m="0.8rem auto" />
        } else {
          const castedMessage = message as GeneralMessageProps
          const variant = castedMessage.isError
            ? MessageVariant.NOT_SENT
            : castedMessage?.isSending
            ? MessageVariant.IS_SENDING
            : castedMessage?.isDeleted
            ? MessageVariant.DELETED
            : MessageVariant.DEFAULT
          return (
            <Container key={castedMessage.clientMessageId} my="0.8rem">
              <Message {...castedMessage} variant={variant} hasAvatar={showAvatar}/>
            </Container>
          )
        }
      })}
      <div ref={messageEndRef} />
    </div>
  )
}
