import React, { SVGProps, FC } from 'react'

export const CodeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.242 3.03a1 1 0 0 0-1.213.728l-1.386 5.547-2.614 10.453a1 1 0 0 0 .729 1.213 1 1 0 0 0 1.213-.729L13.584 9.79l1.387-5.547a1 1 0 0 0-.729-1.213ZM7 7a1 1 0 0 0-.707.293l-4 4a1 1 0 0 0 0 1.414l4 4a1 1 0 0 0 1.414 0 1 1 0 0 0 0-1.414L4.414 12l3.293-3.293a1 1 0 0 0 0-1.414A1 1 0 0 0 7 7Zm9.293.293a1 1 0 0 0 0 1.414L19.586 12l-3.293 3.293a1 1 0 0 0 0 1.414 1 1 0 0 0 1.414 0l4-4a1 1 0 0 0 0-1.414l-4-4a1 1 0 0 0-1.414 0z" />
  </svg>
)
