import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { Conversation } from './conversation'
import { conversationsEntityAdapter, getConversationsInitialState } from './entity-adapter'
import { SyncConversationsPayload } from './sync-conversations'

export interface ConversationsState extends EntityState<Conversation> {
  lastConversationId?: string
  lastConversationTimestamp?: string
}

const initialState: ConversationsState = {
  lastConversationId: undefined,
  lastConversationTimestamp: undefined,
  ...getConversationsInitialState(),
}

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    syncConversations: (state, { payload }: PayloadAction<SyncConversationsPayload>) => {
      return {
        lastChatIdInBatch: payload.lastConversationId,
        lastTimestamp: payload.lastConversationTimestamp,
        ...conversationsEntityAdapter.setAll(getConversationsInitialState(), payload.conversations),
      }
    },
  },
})

export default conversationsSlice.reducer

export const { syncConversations } = conversationsSlice.actions
