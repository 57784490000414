import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { LockIcon, TextIcon } from '@components/icons'
import { useConversationId } from '@hooks/use-conversation-id'
import { useInterfaceLanguage } from '@hooks/use-interface-language'
import { ConversationType } from '@utils/get-chat-type'
import { getFullName } from '@utils/helpers'
import { isNativeChatsApp } from '@utils/is-native-chats-app'
import { Language } from '@utils/locales'
import { format } from 'date-fns'
import { th, enUS } from 'date-fns/locale'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SpaceProps } from 'styled-system'
import { Avatar, Container, Heading, Text } from 'ui'

const LOCALES: Record<Language, Locale> = {
  en: enUS,
  th,
}

interface EmptyChannelMessagesProps extends SpaceProps {
  name?: string
}

export const EmptyChannelMessages: FC<EmptyChannelMessagesProps> = ({ name, ...spacing }) => {
  const conversationId = useConversationId()
  const { data: chatDetails } = useGetChatDetails(conversationId)
  const { t } = useTranslation('create-new')
  const { interfaceLanguage } = useInterfaceLanguage()

  const creatorFullName = useMemo(() => {
    const creator = chatDetails?.chatUsers.find(
      ({ user }) => user.userId === chatDetails.createdBy
    )?.user
    return getFullName(creator?.firstName, creator?.lastName)
  }, [chatDetails])

  const creationFortmattedDate = useMemo(() => {
    if (chatDetails?.createdAt) {
      return format(new Date(chatDetails.createdAt), 'MMMM d, yyyy', {
        locale: LOCALES[interfaceLanguage.value as Language] || enUS,
      }).toString()
    }
    return ''
  }, [chatDetails])
  if (!chatDetails) {
    return null
  }
  return (
    <Container {...spacing}>
      <Container display="flex" alignItems="center" mb="1.7rem">
        <Avatar
          bgColor={`#${chatDetails.color}`}
          icon={
            chatDetails.conversationType === ConversationType.PRIVATE_CHANNEL ? (
              <LockIcon width={28} height={28} />
            ) : (
              <TextIcon width={28} height={28} />
            )
          }
        />
        <Heading variant="h2" as="h2" ml="1.2rem">
          {t('emptyChannelMsg.heading')} {name}
        </Heading>
      </Container>
      {isNativeChatsApp() ? (
        <Text>
          {t('emptyChannelMsg.infoChats', {
            channelName: name,
            date: creationFortmattedDate,
          })}
        </Text>
      ) : (
        <Text>
          {t('emptyChannelMsg.info', {
            channelName: name,
            creator: creatorFullName,
            date: creationFortmattedDate,
          })}{' '}
          {chatDetails.conversationType === ConversationType.PRIVATE_CHANNEL
            ? t('emptyChannelMsg.privateChannelText')
            : t('emptyChannelMsg.publicChannelText')}
        </Text>
      )}
    </Container>
  )
}
