import { MessageDetail } from '@api/messages/types/message-detail'
import { NavigateFunction } from 'react-router-dom'
import {
  Conversation as ConversationModel,
  ConversationUser,
} from 'src/entities/conversations/model/conversation'
import logo from 'ui/public/images/logo.png'
import { ConversationType } from './get-chat-type'

export interface User {
  firstName: string
  lastName: string
}

export interface Conversation {
  conversationType: string
  isMuted: boolean
}

export interface Users {
  [userId: string]: User
}

export interface Conversations {
  [conversationId: string]: Conversation
}

class NotificationsManager {
  private static instance: NotificationsManager
  messages: MessageDetail[]
  users: Users
  conversations: Conversations
  navigate: NavigateFunction | undefined

  private constructor() {
    this.messages = []
    this.users = {}
    this.conversations = {}
    this.navigate = undefined
  }

  public static getInstance(): NotificationsManager {
    if (!NotificationsManager.instance) {
      NotificationsManager.instance = new NotificationsManager()
    }

    return NotificationsManager.instance
  }

  private isChat(chatId: string): boolean {
    return this.conversations[chatId].conversationType === ConversationType.CHAT
  }

  private isMuted(chatId: string): boolean {
    return this.conversations[chatId].isMuted
  }

  private buildUserFullName(userId: string): string {
    return `${this.users[userId].firstName} ${this.users[userId].lastName}`
  }

  private buildTitle(userId: string, isChat: boolean): string {
    return isChat
      ? `New message from ${this.buildUserFullName(userId)}:`
      : `New message in channel from ${this.buildUserFullName(userId)}:`
  }

  private buildNotificationTag(createdAt: string, createdBy: string): string {
    return `${createdAt}${createdBy}`
  }

  private isPageFocused(chatId: string): boolean {
    return window.location.pathname.includes(chatId) && document.hasFocus()
  }

  private showNotification(shiftMessage: boolean = true) {
    let notification: Notification | undefined = undefined

    if (this.messages.length) {
      const msg = shiftMessage ? this.messages.shift() : this.messages[0]

      if (msg && !this.isPageFocused(msg.chatId) && !this.isMuted(msg.chatId)) {
        notification = new Notification(this.buildTitle(msg.createdBy, this.isChat(msg.chatId)), {
          body: msg.originalMessage,
          // Tag prop prevents multiple notifications from different tabs
          tag: this.buildNotificationTag(msg.createdAt.toString(), msg.createdBy),
          icon: logo,
        })

        notification.onclick = (event) => {
          window.focus()

          if (this.navigate) {
            this.navigate(`${this.isChat(msg.chatId) ? 'chats' : 'channels'}/${msg.chatId}`)
          }

          notification?.close()
        }
      }
    }
  }

  private executeNotificationsQueue() {
    const interval = setInterval((): void => {
      this.showNotification()

      if (!this.messages.length) {
        clearInterval(interval)
      }
    }, 1500)
  }

  private sortMessagesByTime() {
    this.messages.sort((a, b) => {
      const firstCreatedAtTime = new Date(a.createdAt).getTime()
      const secondCreatedAtTime = new Date(b.createdAt).getTime()
      return firstCreatedAtTime - secondCreatedAtTime
    })
  }

  public setMappingDataForNotifications(
    conversationsData: ConversationModel[]
  ): NotificationsManager {
    conversationsData.forEach((chat: ConversationModel) => {
      if (!this.conversations[chat.id]) {
        this.conversations[chat.id] = {
          conversationType: chat.conversationType,
          isMuted: chat.isMuted,
        }
      }

      chat.chatUsers.forEach((chatUser: ConversationUser) => {
        if (!this.users[chatUser.user.userId]) {
          this.users[chatUser.user.userId] = {
            firstName: chatUser.user.firstName,
            lastName: chatUser.user.lastName,
          }
        }
      })
    })

    return this
  }

  // Save navigate function to redirect user to target conversation
  // (We are not able to use hook directly in this file)
  public setNavigate(navigateFn: NavigateFunction): NotificationsManager {
    this.navigate = navigateFn

    return this
  }

  public toggleNotificationsForChat(chatId: string, isMuted: boolean) {
    this.conversations[chatId].isMuted = !!isMuted
  }

  public tryToSendNotification(message: MessageDetail) {
    if (this.messages.length) {
      this.messages.push(message)
    } else {
      this.messages.push(message)
      this.executeNotificationsQueue()
    }
    this.sortMessagesByTime()
  }
}

export const notificationsManager = NotificationsManager.getInstance()
